import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1 from "../../images/xl-series/timberline-xl-series-30-cylind-25g-26g-tanks.jpg"
import Image2 from "../../images/TIMBERLINE-L-SERIES-30-C-front-side-WEB-THUMB.jpg"
import Image3 from "../../images/TIMBERLINE-L-SERIES-30-C-front-side-back-WEB-THUMB.jpg"
import Image4 from "../../images/TIMBERLINE-L-SERIES-30-C-front-side-back-twist-WEB-THUMB.jpg"

import Image1Large from "../../images/xl-series/timberline-xl-series-30-cylind-25g-26g-tanks.jpg"
import Image2Large from "../../images/TIMBERLINE-L-SERIES-30-C-front-side-WEB-Large.jpg"
import Image3Large from "../../images/TIMBERLINE-L-SERIES-30-C-front-side-back-WEB-Large.jpg"
import Image4Large from "../../images/TIMBERLINE-L-SERIES-30-C-front-side-back-twist-WEB-Large.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const XL30CSPage = () => {
  return (
    <>
      <SEO title="XL30 Floor Scrubber | Timberline Cleaning Equipment" />
      <Helmet>
        <title>XL30 Floor Scrubber | Timberline Cleaning Equipment</title>
        <description>
          With powerful performance and a durable design, the L30 is a Battery
          Floor Scrubber for use typically in large, open areas. Our cylindrical
          head and cleans specialty applications like Grouted Tile and Rubber
          Field Tracks with ease while offering the ability to sweep and scrub
          in one pass. The (2x) 24-inch (61 cm) cylindrical roller style scrub
          brush is installed and removed without tools to allow ease when
          switching between different brushes, depending upon the application’s
          needs. The squeegee blades can be rotated or flipped without tools.
          Save time and money by practically eliminating the need to pre-sweep
          with this Cylindrical Scrubber.
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              image1={Image1}
              image2={Image2}
              image3={Image3}
              image4={Image4}
              image1Large={Image1Large}
              image2Large={Image2Large}
              image3Large={Image3Large}
              image4Large={Image4Large}
              mobileLink="#demo-section"
              mobileTitle="XL30 Cylindrical"
            />
            <ProductDescription
              productTitle="XL30 Cylindrical"
              subHeading="Walk-Behind Large Floor Scrubbers"
              modelNum="XL30C"
              productDescription="With powerful performance and a durable design, the L30 is a Battery Floor Scrubber for use typically in large, open areas.  Our cylindrical head and cleans specialty applications like Grouted Tile and Rubber Field Tracks with ease while offering the ability to sweep and scrub in one pass.  The (2x) 24-inch (61 cm) cylindrical roller style scrub brush is installed and removed without tools to allow ease when switching between different brushes, depending upon the application’s needs.  The squeegee blades can be rotated or flipped without tools.  Save time and money by practically eliminating the need to pre-sweep with this Cylindrical Scrubber."
              link="#demo-section"
              list1="Constructed robustly — steel frame construction and durable components "
              list2="Simplify maintenance with easy access to the battery and recovery tank"
              list3="Traction drive speed controlled with easy to use Drive Speed Control thumb dial"
              list4="Easily operate and maneuver with push button forward and toggle reverse"
              list5="Clean any hour of the day with approved sound levels < 67 dBA"
              list6="Increase productivity with over 3 hours of run time between dump and refill"
              list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
              brochure="../../timberline-walk-behind-sm.pdf"
              tech="../../tl-xl-series-tech-specs-v21263.pdf"
              parts="https://www.dropbox.com/s/6ubm2v07fsauqqt/t-xl-series-p-en.pdf?dl=0"
              operator="https://www.dropbox.com/s/ltqd19o13g20046/t-xl-series-op-en.pdf?dl=0"
              hood="../../timberline-underthehood.pdf"
              pm="../../XL-Series-PM.pdf"
              mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
              />
          </div>
          <div id="demo-section"></div>
          <IndexSection3 title="Request a Free Demo at your Facility" />
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default XL30CSPage
